import React, { useEffect } from "react";
// import AuthLayout from "../../components/";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  Typography,
} from "antd";
import { useNavigate } from "react-router";
import { ImageUrl } from "../../config/helper";
import { FaEnvelope, FaRegUser } from "react-icons/fa";
import { CiMobile1 } from "react-icons/ci";
import { GoKey } from "react-icons/go";
import { Post } from "../../config/api/post";
import { AUTH, BACKGROUND_VIDEO, UPLOADS_URL } from "../../config/constants/api";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { Background } from "../home";
// import router from "next/router";

function Signup() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

// useEffect(() => {
//   navigate("/confirmOtp");
// }, []);

  // const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  // const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  // React.useEffect(() => {
  //   if (user && token) {
  //     navigate("/", { replace: true });
  //   }
  // }, [user, token]);

  const onFinish = (values) => {
    setLoading(true);
    let data = new FormData();
    data.append("email", values?.email);
    data.append("password", values?.password);
    data.append("firstName", values?.firstName);
    data.append("lastName", values?.lastName);
    data.append("phone", values?.phone);
    // console.log(AUTH.signup);
    // sendOtp



    Post(AUTH.signup, data)
    .then((response) => {
      if (response?.status) {
        setLoading(false);
        swal("Success!", "Check Your Email To Verify OTP", "success");
        navigate("/confirm-otp",{ state: { email: values.email } });
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message || err?.message;
      setLoading(false);
      console.log(":::;", err);
      if (message) swal("Oops!", message, "error");
    });




  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // const handleChange = (value) => {
  //   console.log(`selected ${value}`);
  // };
  return (
    <>
      <Background />
      <Layout className="banner">
        <Row className="flex justify-center items-center">
          <Col xs={22} md={20} lg={16}>
            <Card>
              <div className="authFormBox checkout-form">
                <Row style={{ width: "100%", justifyContent: "center" }}>
                  <Col xs={20} md={20} className="formWrap">
                    <h3>REGISTER</h3>
                    <p>Fill out the form below to register</p>
                    <br />
                    <Form
                      layout="vertical"
                      name="basic"
                      className="loginForm"
                      labelCol={{
                        span: 0,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      form={form}
                      // autoComplete="off"
                    >
                      <Row gutter={20}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Enter First Name"
                            name="firstName"
                            rules={[
                              {
                                type: "text",
                                message: "Please Enter First Name",
                                // warningOnly: true,
                              },
                              {
                                required: true,
                                message: "Please Enter First Name!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter First Name"
                              prefix={<FaRegUser />}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <Form.Item
                            label="Enter Last Name"
                            name="lastName"
                            rules={[
                              {
                                type: "text",
                                message: "Please Enter Last Name",
                                // warningOnly: true,
                              },
                              {
                                required: true,
                                message: "Please Enter Last Name!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Last Name"
                              prefix={<FaRegUser />}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={12} lg={12}>
                          <Form.Item
                            label="Enter Email Address"
                            name="email"
                            rules={[
                              {
                                type: "email",
                                message: "Please Enter Email Address",
                                // warningOnly: true,
                              },
                              {
                                required: true,
                                message: "Please Enter Email Address!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Email Address"
                              prefix={<FaEnvelope />}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <Form.Item
                            label="Enter Number"
                            name="phone"
                            rules={[
                              {
                                type: "",
                                message: "Please Number",
                                // warningOnly: true,
                              },
                              {
                                required: true,
                                message: "Please Enter Valid Number!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Number"
                              prefix={<CiMobile1 />}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Password*"
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                            ]}
                          >
                            <Input.Password
                              size="large"
                              placeholder="Enter Password"
                              className="AuthFormInput"
                              prefix={<GoKey />}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Confirm Password"
                            name="confirmPassword"
                            dependencies={["password"]}
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("The two passwords do not match!")
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              size="large"
                              placeholder="Confirm Password"
                              className="AuthFormInput"
                              prefix={<GoKey />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <br />

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="loginButton mainbtn"
                        >
                          {loading ? "Loading..." : "Continue"}
                        </Button>
                      </Form.Item>
                    </Form>

                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        textAlign: "left",
                        marginTop: 0,
                        marginBottom: 30,
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      <>
                        Already have an account?{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            color: "#000",
                          }}
                          onClick={() => navigate("/login")}
                        >
                          Sign In
                        </span>
                      </>
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default Signup;
