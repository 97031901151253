import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from './slice/authSlice';
import { cartApi } from '../api/cart';
import { ticketApi } from '../api/ticket';
import { giveawayApi } from '../api/giveaway';
import { aptPayApi } from '../api/aptPay';
import { profileApi } from '../api/profile';
import { postApi } from '../api/post';
import {chatApi} from "../components/chat/chatApiSlice"


const rootReducer = combineReducers({
  user: userReducer,
  [cartApi.reducerPath]: cartApi.reducer,
  [ticketApi.reducerPath]: ticketApi.reducer,
  [giveawayApi.reducerPath]: giveawayApi.reducer,
  [aptPayApi.reducerPath]: aptPayApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,

})

const persistConfig = {
  key: 'real_money_dragon_user',
  storage,
}

// const persistedReducer = persistReducer(persistConfig, rootReducer)
const persistedReducer = rootReducer

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cartApi.middleware).concat(ticketApi.middleware).concat(giveawayApi.middleware).concat(aptPayApi.middleware).concat(profileApi.middleware).concat(postApi.middleware).concat(chatApi.middleware),
})

export const persistor = persistStore(store)