import { Button, Col, Form, Image, Input, Row } from "antd";
import React, { useState } from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { ImageUrl } from "../../config/helper";

function RequestCoins() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.user.userToken);

  const submit = (values) => {
    try {
        setLoading(true);
        console.log("values", values)
        let data = {
          coins: +values.amount,
          note: values.note
        }
        Post('/coins/request', data, token)
          .then(async (response) => {
            setLoading(false);
            if (response?.status) {
              navigate('/')
              form.resetFields();
              swal("System Alert", response?.message, "success");
            }
          })
          .catch((err) => {
            setLoading(false);
            let message = err?.response?.data?.message || err?.message;
            console.log(":::;", err);
            if (message) swal("Oops!", message, "error");
          });
    } catch (error) {
        setLoading(false);
        swal("Oops!", error.message, "error");
    }
  }

return (
    <div className="innerpage">
        <Row justify={"center"}>
            <Col xs={22} md={20}>
                <h5>
                    <IoIosArrowDropleftCircle onClick={() => navigate(-1)} />
                    REQUEST COINS
                </h5>

                <div className="about-section product-details">
                    <Row gutter={20} justify={'between'}>
                        <Col xs={24} md={14} lg={12}>
                            <span className="card-title">Request Red Coins</span>
                            <br />
                            <Form
                                name="basic"
                                onFinish={submit}
                                form={form}
                            >
                                <Form.Item
                                    name="amount"
                                    rules={[{ required: true, message: 'Please input amount!' }]}
                                >
                                    <Input placeholder="Amount" />
                                </Form.Item>
                                <Form.Item
                                    name="note"
                                    rules={[{ required: false }]}
                                >
                                    <Input.TextArea placeholder="Notes" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                                        Request
                                    </Button>
                                </Form.Item>
                            </Form>

                        </Col>
                        <Col xs={24} md={10} lg={12}>
                            <Image
                                preview={false}
                                src={ImageUrl("redeem-coin-red.jpg")}
                                className="mb-15"
                                alt="card"
                                height={500}
                                width={500}
                            />
                        </Col>

                    </Row>
                </div>
            </Col>
        </Row>
    </div>
)
}

export default RequestCoins