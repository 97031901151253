import { Layout,Row,Col, Card, Avatar, List } from 'antd'
import React from 'react'
// import Packages from '../../components/packges'

function Managesubscription() {
  return (
    <>
      <Row justify="center">
        <Col xs={24} md={24} xl={24}>
          <h4 style={{ textAlign: "center", color:"#fff", margin:"0 0 10px" }}>Wishlist</h4>
          <Card>
           {/* <Packages fullWidth = {true} title={false}/> */}
              
              
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Managesubscription