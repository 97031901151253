import React from 'react'
import { Row, Col, Image } from "antd";
import { ImageUrl } from "../../config/helper";
import Faqs from '../../components/faqs';
import ContactUsInfo from '../../components/contactUsInfo';

function ContactUs() {
  return (
    <div className="innerpage">
        <Row justify={"center"}>
            <Col xs={22} md={20} >
                <h5>Contact Us</h5>
                <Faqs/>
                <ContactUsInfo/>
            </Col>
        </Row>
    </div>
  )
}

export default ContactUs