import React, { useState } from "react";
import { Row, Col, Image, Button, Spin } from "antd";
import { ImageUrl } from "../../config/helper";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import CardFrame from "../../components/card";
import QuantitySelector from "../../components/quantitySelector";
import ProductSlider from "../../components/productSlider";
import { useQuery } from "react-query";
import { Get } from "../../config/api/get";
import { CART, PRODUCTS } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { useSelector } from "react-redux";
import swal from "sweetalert";

function ProductDetails() {
  const token = useSelector((state) => state.user.userToken);
  const { id } = useParams();
  const location = useLocation();
  const products = location?.state?.products;
  const getProduct = async () => {
    const response = await Get(`${PRODUCTS.getProductById}${id}`);
    if (response?.success) {
      return response?.product;
    } else {
      throw new Error("Error fetching user");
    }
  };
  const { data, error, isLoading } = useQuery(
    "getProduct", // Unique query key based on the user ID
    getProduct,
    {
      retry: 1, // Optional: Retry once if the query fails
      onError: (err) => console.log(err),
    }
  );
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const handleSelectedQuantityChange = (newQuantity) => {
    setSelectedQuantity(newQuantity);
  };

  const navigate = useNavigate();

  const handleAddToCart = () => {
    if (token) {
      let data = { productId: id, quantity: selectedQuantity };
      Post(CART.addProduct, data, token)
        .then((response) => {
          if (response?.success) {
            navigate("/cart")
            swal("System Alert", response?.message, "success");
          }
        })
        .catch((err) => {
          let message = err?.response?.data?.message || err?.message;
          console.log(":::;", err);
          if (message) swal("Oops!", message, "error");
        });
    } else {
      navigate("/login")
    }
  };
  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5>
            <IoIosArrowDropleftCircle onClick={() => navigate("/shop")} />{" "}
            Product Details
          </h5>

          {!isLoading ? (
            <div className="about-section product-details">
              <Row gutter={20}>
                {data && (
                  <Col xs={24} md={12} lg={9}>
                    <CardFrame item={data} />
                  </Col>
                )}
                <Col xs={24} md={12} lg={15}>
                  <h3 style={{fontFamily: 'monospace', textTransform: 'uppercase'}}>{data?.title}</h3>
                  <h4>$ {data?.price}</h4>
                  <p>{data?.description}</p>
                  <div className="quantity">
                    <QuantitySelector
                      value={selectedQuantity}
                      onChange={handleSelectedQuantityChange}
                    />
                  </div>
                  <Button
                    className="mainbtn"
                    // onClick={() => navigate("/cart")}
                    onClick={handleAddToCart}
                  >
                    Add to Cart
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <Spin />
          )}
        </Col>
      </Row>

      {products && (
        <ProductSlider
          title={"Buy Your Favorite Card"}
          heading={"similar products"}
          products={products}
        />
      )}
    </div>
  );
}

export default ProductDetails;
