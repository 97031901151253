// export const BASE_URL = "https://secure.demo243.webhostlabs.net:3002/api/"
// export const UPLOADS_URL = "https://secure.demo243.webhostlabs.net:3002/Uploads/"
const { NODE_ENV } = process.env;
const { hostname } = window.location;

const servers = {
  local: "http://localhost:3032",
  customDev: "https://react.customdev.solutions:3032",
  live: "https://realmoneydragon.io:3032",
  dummy: "https://6691-110-93-244-176.ngrok-free.app",
};

var URL;
if (NODE_ENV === "production" && hostname.includes("react.customdev.solutions"))
  URL = servers.customDev;
else if (NODE_ENV === "production" && hostname.includes("realmoneydragon.io"))
  URL = servers.live;
else URL = servers.live;

export const SOCKET_URL = `${URL}`;
export const UPLOADS_URL = URL + "/";
export const BASE_URL = URL + "/api";

export const BACKGROUND_VIDEO = UPLOADS_URL + "Uploads/" + "background-video.mp4";

export const AUTH = {
  signin: "/auth/signIn",
  signup: "/auth/signUp",
  emailCode: "/auth/emailVerificationCode",
  verifyCode: "/auth/verifyRecoverCode",
  resetPassword: "/auth/resetPassword",
  sendOtp: "/auth/send-otp",
  verifyOtp: "/auth/verify-otp",
};
export const PRODUCTS = {
    getAllProducts : "/product",
    getProductById : "/product/",
}
export const CART = {
  addProduct : "/cart/addProduct",
  getMyCart : "/cart/myCart",
  updateProduct : "/cart/updateProduct",
  removeProduct: "/cart/removeProduct",
}
export const ORDER = {
  placeOrder : '/order',
  getMyOrder: 'getMyOrders'
}
export const STUDENT = {
  updateProfile: "/profile/updateProfile",
  changePassword: "/profile/changePassword",
  getMyCoaches: "/profile/getMyCoaches",
};

export const USERS = {
  get: "/auth/signin",
  getAllCoaches: "/admin/user/getAllCoaches",
  getAllTutors: "/admin/user/getAllTutors",
  getCoachById: "/admin/user/getCoachById/",
};

export const COACH = {
  updateProfile: "/coach/profile/updateProfile",
  changePassword: "/coach/profile/changePassword",
};

export const SERVICES = {
  getAll: "/service/getAllServices",
};

export const RATES = {
  getMyRates: "/rates/getMyRates",
  setRates: "/rates/setRates",
};

export const COMISSSION = {
  getComission: "/comission/getComission",
};

export const SCHEDULE = {
  addSchedule: "/schedule/addSchedule",
  getMySchedule: "/schedule/getMySchedule",
  getScheduleByCoachId: "/schedule/getScheduleByCoachId/",
};

export const REVIEWS = {
  getAll: "/review/getAllReviewsByCoachId/",
  getCoachRatings: "/review/getCoachRatings/",
};

export const LESSON = {
  bookLesson: "/lesson/addLesson",
  getUpcomingLessons: "/lesson/getAllUpcomingLessons",
  getCompletedLessons: "/lesson/getAllCompletedLessons",
  getLiveLessons: "/lesson/getAllLiveLessons",
  getLessonById: "/lesson/getLessonById/",
};

export const PAYMENT = {
  lessonPayment: "/payment/lessonPayment",
};
