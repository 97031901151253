import { Button, Card, Empty, Flex, Image, List, Modal, Tabs, Typography } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import swal from "sweetalert";
import { useEnterGiveawayMutation, useGetGiveawayByIdQuery } from '../../api/giveaway';
import QuantitySelector from '../../components/quantitySelector';
import { ImageUrl } from "../../config/helper";
import Timer from '../../config/helpers/Timer';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { NODE_ENV } = process.env;

const timeZone = NODE_ENV === "development" ? "local" : "local";

export function getGiveawayStatus(startTime, endTime) {
    const start = moment(startTime)?.[timeZone]();
    const end = moment(endTime)?.[timeZone]();
    const current = moment();
    if (current.isBetween(start, end)) {
        return "Active";
    } else if (current.isBefore(start)) {
        return "Upcoming";
    } else {
        return "Ended";
    }
}

export const giveawayName = {
    ['G-00']: "12AM - 2AM",
    ['G-01']: "1AM - 3AM",
    ['G-02']: "2AM - 4AM",
    ['G-03']: "3AM - 5AM",
    ['G-04']: "4AM - 6AM",
    ['G-05']: "5AM - 7AM",
    ['G-06']: "6AM - 8AM",
    ['G-07']: "7AM - 9AM",
    ['G-08']: "8AM - 10AM",
    ['G-09']: "9AM - 11AM",
    ['G-10']: "10AM - 12PM",
    ['G-11']: "11AM - 1PM",
    ['G-12']: "12PM - 2PM",
    ['G-13']: "1PM - 3PM",
    ['G-14']: "2PM - 4PM",
    ['G-15']: "3PM - 5PM",
    ['G-16']: "4PM - 6PM",
    ['G-17']: "5PM - 7PM",
    ['G-18']: "6PM - 8PM",
    ['G-19']: "7PM - 9PM",
    ['G-20']: "8PM - 10PM",
    ['G-21']: "9PM - 11PM",
    ['G-22']: "10PM - 12AM",
    ['G-23']: "11PM - 1AM",
}

const GiveawayDetails = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const { data: giveaway, refetch } = useGetGiveawayByIdQuery(id);
    const [enterGiveaway, { isLoading }] = useEnterGiveawayMutation();
    const user = useSelector((state) => state.user.userData);
    const myEntries = giveaway?.entries?.find(x => x.userId?._id === user?._id)?.entryCount || 0;
    const giveawayStatus = getGiveawayStatus(giveaway?.startTime, giveaway?.endTime);
    const timer = giveawayStatus === 'Active' ? giveaway?.endTime : giveaway?.startTime;
    const { days, hours, minutes, seconds } = Timer(timer);
    const dateFormat = "Do MMMM YYYY, h:mm:ss A";
    const startTime = moment(giveaway?.startTime)?.[timeZone]()?.format(dateFormat);
    const endTime = moment(giveaway?.endTime)?.[timeZone]()?.format(dateFormat);
    let hour = moment(giveaway?.startTime).get('hour');
    if (hour < 10) {
        hour = `0${hour}`;
    }
    const code = `G-${hour}`;
    const [entries, setEntries] = useState(0);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        console.log('Clicked ok button');
        await enterGiveaway({ giveawayId: giveaway?._id, entries }).then(({ data, error }) => {
            if (data?.status) {
                swal("Success", data?.message, "success");
                setOpen(false);
                setEntries(0);
                refetch();
            } else {
                swal("Error", data?.message || error?.data?.message || "An error occured", "error");
            }
        }
        );
    };

    const handleCancel = () => {
        setOpen(false);
        setEntries(0);
    };

    const handleSelectedQuantityChange = (value) => {
        setEntries(+value);
    };

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <>
            <Modal
                title="Enter in this giveaway"
                open={open}
                onOk={handleOk}
                confirmLoading={isLoading}
                onCancel={handleCancel}
            >
                <br />
                <Flex justify='center' gap={5}>
                    <Text style={{ marginTop: 8 }}>Entries: &nbsp;&nbsp;</Text>
                    <QuantitySelector
                        value={entries}
                        onChange={handleSelectedQuantityChange}
                    />
                </Flex>
                <br />
                <Flex justify='flex-end' gap={5}>
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button key="submit" type="primary" loading={isLoading} disabled={isLoading} onClick={handleOk}>
                        Enter
                    </Button>
                </Flex>
            </Modal>
            <div>
                <div className="position-relative">
                    <Image preview={false} src={ImageUrl("dragon-bg.png")} className="bg-vid" />
                </div>
                <div className="innerpage">
                    <div style={{ padding: '20px' }}>
                        <Card bordered={true} style={{ marginBottom: '20px', background: 'linear-gradient(to right, #b6fbff, #83a4d4)' }}>
                            <IoMdArrowRoundBack
                                onClick={handleBack}
                                size={30} className="text-black hover:cursor-pointer" />
                            <Flex justify="space-between">
                                <div>
                                    <Title level={3}>{giveaway?.name}</Title>
                                    <Text>Prize: {giveaway?.prize} Gold Coins</Text><br />
                                    <Text>Time: {giveawayName[code]}</Text><br />
                                    <Text>Start: {startTime}</Text><br />
                                    <Text>End: {endTime}</Text><br />
                                    <Text>Status: {giveawayStatus}</Text><br />
                                    <Text>Your Entries: {myEntries}</Text>
                                </div>
                                <div>
                                    <Title level={3}>
                                        {/* add timer here */}
                                        {giveawayStatus === 'Upcoming' ? (
                                            <Text>Starts in: {hours} H {minutes} M {seconds} S </Text>
                                        ) : giveawayStatus === 'Active' ? (
                                            <Text>Ends in: {hours} H {minutes} M {seconds} S </Text>
                                        ) : (
                                            <Text>Ended</Text>
                                        )}
                                    </Title>
                                    <br />
                                    {giveawayStatus === "Active" ? <Button className='mainbtn' onClick={showModal} disabled={giveawayStatus !== "Active"}>ENTER</Button> : null}
                                </div>
                            </Flex>
                        </Card>

                        <Tabs defaultActiveKey="1" centered>
                            <TabPane tab="Entries" key="1">
                                <Title level={4} style={{ color: 'white' }}>Entries</Title>
                                {giveaway?.entries?.length > 0 ? (
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={giveaway?.entries}
                                        renderItem={(entry) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<span style={{ color: 'white' }}>{`${entry?.userId?.firstName} ${entry?.userId?.lastName}`}</span>}
                                                    description={
                                                        <>
                                                            {/* <span style={{ color: 'white' }}>{`Email: ${entry?.userId?.email}`}</span>
                                                            <br /> */}
                                                            <span style={{ color: 'white' }}>{`Entries: ${entry?.entryCount}`}</span>
                                                        </>
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                ) : (
                                    <>
                                        <Empty description="No entries found." />
                                    </>
                                )}
                            </TabPane>

                            <TabPane tab="Winners" key="2">
                                <Title level={4} style={{ color: 'white' }}>Winners</Title>
                                {giveaway?.winners?.length > 0 ? (
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={giveaway?.winners}
                                        renderItem={(winner) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<span style={{ color: 'white' }}>{`${winner?.firstName} ${winner?.lastName}`}</span>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                ) : (
                                    <Empty description="No winners found." />
                                )}
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GiveawayDetails
