import React from "react";
import { Row, Col, Image, Button } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTiktok, FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { icons } from "../../assets";
import { ReactComponent as Facebook } from './../../assets/icons/facebook.svg';
import { ReactComponent as Instagram } from './../../assets/icons/instagram.svg';
import { ReactComponent as Discord } from './../../assets/icons/discord.svg';
import { ReactComponent as Twitter } from './../../assets/icons/twitter.svg';
import { ReactComponent as Tiktok } from './../../assets/icons/tiktok.svg';

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer z-index-99 bottom-0">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <Row>
            <Col xs={24} md={24} lg={10}>
              <p>Real Money Dragon &copy; 2024 - All Right Reserved</p>
            </Col>
            <Col xs={24} md={24} lg={14}>
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="flex justify-center items-center">
                    <img alt="svgImg" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OHB4IiBoZWlnaHQ9IjQ4cHgiPiAgICA8cGF0aCBkPSJNMTksM0g1QzMuODk1LDMsMywzLjg5NSwzLDV2MTRjMCwxLjEwNSwwLjg5NSwyLDIsMmg3LjYyMXYtNi45NjFoLTIuMzQzdi0yLjcyNWgyLjM0M1Y5LjMwOSBjMC0yLjMyNCwxLjQyMS0zLjU5MSwzLjQ5NS0zLjU5MWMwLjY5OS0wLjAwMiwxLjM5NywwLjAzNCwyLjA5MiwwLjEwNXYyLjQzaC0xLjQyOGMtMS4xMywwLTEuMzUsMC41MzQtMS4zNSwxLjMyMnYxLjczNWgyLjcgbC0wLjM1MSwyLjcyNWgtMi4zNjVWMjFIMTljMS4xMDUsMCwyLTAuODk1LDItMlY1QzIxLDMuODk1LDIwLjEwNSwzLDE5LDN6Ii8+PC9zdmc+"/>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJUlEQVR4nO2Y0UobQRSGP1KaXDT0BZLeVDF9GyVtIsVXUG+07StU7Vs0hKZa7EWxb1DbK3tXJeYh1NxEKiMDJ7AMSXZnMruTwPxwYJmdOfP/7Dmz5wxERERELBPKwCbQBS6BIaDmtKH40j7bskcueA0MPBBOs2ug6ZP4E+BTAcSVYUdAyYeAEOSV2IGPsCmK7A+gBtSBs8T4hiv5ckExr8Q08TFeJMb7rom9WXC41KcI0NZyEdAtWMCZiNDkfxrvOi4CrhxIjIAvwFugATwTa8hYT+bY+r10EXBruckJ8DKD3xXgm6XvWxcBWZ3/B/Yc/O8DDxb75CbAhfwY70ML0GFjogLsAr+lzhnK8468M3EaSsBoQszrH9HfGWsuZE4SqxkT27sAfdokUUkhnxRhfomvIQToYzGJXYt43jbWboUQsGbM/2Mh4NxY2wghoGrMv7MQoOcmUV02ATfG2ucxhDwk8c6yJXHPmF+RIzJt3cWE+v44hICRFGZJ1FJETPuR3YcQoKSqNFGWEDmXxNb2S8YmdVbfM+6ViwAlVaUrPljsk5sAXRK/c6xEH/IUYNvQnEo8p2HVImzUPA2NS0t5L4WZPhZfyc+uKs9bctpkSVjlo6UsuqlXM+yzi4D2AhBXYm9cBDyVS6XQ5Afz3Fg3F0DAOnPiKCD5j3iAvuI+DHQzXcIjNgrKib6PsJmV2C25q/xn2cRMszvx1ZHTRu8RERERwXLgERxl4HPNXNCYAAAAAElFTkSuQmCC" alt="instagram-new--v1" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVR4nO2ZPUskMRjHfyq+lVqeKJwsaGNzncqBioLgFzhEvO+ghWjjIdiIvR6nnZUIi4p+A9HurlARxessfAVXxEojgWcgDI7rjtlkB/KHfzN5MvP8Msnk2SwEBQUFVbJagQ2gACjHLgB5oOMzyd96SFzFfCe5lKyNCkheidfTAPiYNirB92kAVIW5ZPlOWAUA/I+6ClOIj43ODbAIjADtxv7xIlZy7SswDCwA15XwBh6BaaAx1jcHtAG14ja5ZqoBmJF7eAHQI9vN59VjvCWnANvY044PgD6LAAOuAU6wr1OXAHP282feJUDvG/GdwD/gb0INX6z9uyuAB/k0xrVpxORTtNcV+aRaA9hLiD82Yo5StGvtuwBYSohfNWJWUrRr/XYBMJEQ3yyJaTelaNeadAHwg/Jp1AXAIOXTkAuAQ+BLLLYLWAPG5RNZ/8b96qXtp8TqPqZaZHE72QeugDGg2ojPyfxWUpzp45AL8Z1RsP2JVaY1Al6svLYKEFlv/7NAvyzS9xZh5EmJ1X1+AWcffFZZAFy6ZPlOWAUA/I+6ClOIDC/iQtYB8g6T2wKebAN0yA7qAmAZ+Ab8twmA/DOyLufz5QQ4l+fpHXrXJoAPVQFTwHNWASLpWukyywDRFD7IMkB0CJz0GzoI33oFEertAk8lx7kAAAAASUVORK5CYII=" alt="discord-new-logo"></img>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2ZzYtOcRTHP4ZmjFKI0MhCiaSwGUvlpVD+ACuJrGamNAtZeFnYiCnFwj8grwtRdlLUDLN5KGJBIuWlxtszhfHy06nvU6fpznjufbi/H+63zub7O+d3z/f+Xs557gMVKlSokDo6gD7gNjAKhD9so3pWr57dErqAuyUkHSawmnIo/OZjJh+ciEIr0ZdA8kHWU0TAnQQSD7KhIgLqCSQeZJZLboTELDf+aQE3gamTxB5rIqFD8p0J3IuxAv0tXMHngCl6CVdjbaHPwCr5Hnb8fnErgE8ZcbfcvX4q9hl4AHQC03TVGfcV6NYc/eP8nwDzNLY35hnwNiD/JcBHcQ+BGUAbcF3cCLBMvluBb6kI+A6sV8wex58UZ73MK+ezpmCNyY08kz8HZinuirgfwBZxc52YFwWSD/nTz/+AS4qzPf5S3GtgvnjbUsMFkw9lCDDbrtjNWgHjLouzFXqWuoD3wGLFn3b8LnEbnbAkBTSqdJu2zCP3K2up5j2RuoARYKHm6FZdMH5Q9WI6cD9VAWPAhnHzHHTjB8StBr6kKMDqgGEBcM1V6UGN22qslc++1AQcUVynayuOZ1Tpx+pA7ZzcSEXABSVkHebZCar0bsfbDWVYBLyNLWBYN47haMa4Vd45Gr/o+G3idsQU8NRV2p2T+J1xLUWjSr/RWTGcjyHgA7BSvuuauFUaVToLs9VPlSZgTFXVsLzJffzOVeksbPpFlf6tAmJYbvz1AuoJJB3cefv/Pi32JpB4aOXjboc+bcdOvga0UxBdkUXUWvmDo4F2LeFQSQe7ru61p5U3X6FChQqUgp+oWVqHPVoxJwAAAABJRU5ErkJggg==" alt="twitterx--v1" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZElEQVR4nO2ZPU4DMRCFP4rQkBwADgERBVFKOuAO9EFJyUUCouAGuQgQQULJAfgRNWyKUKBBSJPGEJa12d2x8JNetRrP+/xXeCEpKSnJulaBATAGZoCU7BlwBfS1d5A2gNsKQssST4H1kJmvM7yoJ0DDB2BgILyoj3wAxgaCi/rSByAzEFzUrz4AYsyFVXdgsQLg6jl2gOPYAVaA85gBFjoE7mIGKNonAcgfrcBm7CvwDoyAXaBlCWAODIEOsPZD47LO0hcVGfwR2FpSbx5g/k34qACGOfXmAXacugPdUtEANJ26h5zGUQHcO99aFgE6Tt2+QnyG33O+dS0CnBQY88zqNdr+xXjbwJtFANFbp50T/ikgvJQNIDq7p7rPm+qubpuQmZeqAMp2YUUPkBkILeqXf/m02DcQXNQ93+f1qYHw177P6+jPhUmN4W9CfnAs1ND3+YuKDnamvXohM5+UlJREJfoAjQFWREM2HE4AAAAASUVORK5CYII=" alt="tiktok"></img>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <ul>
                    <li onClick={() => navigate("/privacy-terms")}><Link >Terms & Conditions</Link></li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
