import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "antd";
import { ImageUrl } from "../../config/helper";
import GiveAwayCard from "../../components/giveAwayCard";
import {
  useGetAllGiveawaysQuery,
  useLazyClaimRewardQuery,
} from "../../api/giveaway";
import useFetch from "../../hooks/useFetch";
import { giveawayName } from "./giveawayDetails";
import moment from "moment";
import swal from "sweetalert";
import ClaimReward from "../../components/ClaimReward/ClaimReward";

function GiveaWay() {
  // const { data: giveaway } = useGetAllGiveawaysQuery();
  let { data: giveaway } = useFetch("/giveaway");
  giveaway = giveaway?.data?.giveaways;
  const [triggerClaimReward, { isLoading, isSuccess, isFetching }] = useLazyClaimRewardQuery();

  // Trigger claim reward
  function claimReward() {
    triggerClaimReward()
      .then((res) => {
        console.log(res)
        if (res?.isSuccess) {
          swal("Claimed ", res?.data?.message, "success");
        } else {
          swal(
            "Already Claimed",
            res?.error?.data?.message || "Something went wrong.",
            "warning"
          );
        }
      })
      .catch((err) => {
        swal("Error", err?.message || "Something went wrong.", "error");
      });

  }

  return (
    <div>
      <div className="position-relative">
        <Image
          preview={false}
          src={ImageUrl("dragon-bg.png")}
          className="bg-vid"
        />
      </div>
      <div className="innerpage">
        <Row justify={"center"}>
          <Col xs={22} md={20} className="text-center">
            <Image
              preview={false}
              src={ImageUrl("giveawaybanner.png")}
              alt=""
            />
          </Col>

          {/* Claim reward */}
          <Col xs={22} md={20}>

            <ClaimReward isLoading={isLoading || isFetching} claimReward={claimReward} />
          </Col>

          <Col xs={22} md={20}>
            <h5 className="mt-50">CURRENT CONTEST</h5>
          </Col>
          <Col xs={22} md={20}>
            <Row gutter={40} justify={"center"}>
              {giveaway?.map((item) => {
                return (
                  <Col xs={22} md={12} lg={8}>
                    <GiveAwayCard item={item} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default GiveaWay;
