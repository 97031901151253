import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants/api';
import { prepareHeaders } from './cart';

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/user", prepareHeaders }),
    tagTypes: ['Profile'],
    keepUnusedDataFor: 0,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => "/profile",
            transformResponse: (response) => response?.data?.user,
        }),
        updateProfile: builder.mutation({
            query: (body) => ({
                url: "/profile",
                method: "PATCH",
                body,
            }),
            invalidatesTags: ['Profile'],
        }),

    }),
})

export const { useGetMeQuery, useUpdateProfileMutation } = profileApi
