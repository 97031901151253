import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Upload,
} from "antd";
import React, { useState } from "react";
import { TbCameraPlus } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useGetMeQuery, useUpdateProfileMutation } from "../../api/profile";
import { UploadOutlined } from "@ant-design/icons";
import { addUser, updateUserData } from "../../redux/slice/authSlice";
import { RiEdit2Fill } from "react-icons/ri";
import { UPLOADS_URL } from "../../config/constants/api";

function EditProfile() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState();
  const { data: currentUser, refetch } = useGetMeQuery();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  // get me query

  // const onFinish = async (values) => {
  //   let { data } = await updateProfile({ ...values });

  //   if (data?.status) {
  //     setEditMode(false);
  //     refetch();
  //     dispatch(updateUserData({ user: data?.data?.user }));
  //     swal({
  //       title: "System Message!",
  //       text: "Your Profile Has Been Updated Successfully!",
  //       icon: "success",
  //       buttons: {
  //         confirm: { text: "Okay", className: "graden-bg " },
  //       },
  //     });
  //   }
  // };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      // Append all form values to FormData
      for (const key in values) {
        formData.append(key, values[key]);
      }

      if (image) {
        formData.append("image", image);
      }

      let { data } = await updateProfile(formData);
      console.log(data);
      if (data?.status) {
        setEditMode(false);
        refetch();
        dispatch(updateUserData({ user: data?.data?.user }));
        swal({
          title: "System Message!",
          text: "Your Profile Has Been Updated Successfully!",
          icon: "success",
          buttons: {
            confirm: { text: "Okay", className: "graden-bg " },
          },
        });
      }
      setImage(null);
    } catch (error) {
      console.error("Error updating profile:", error);
      swal({
        title: "Error!",
        text: "There was an issue updating your profile.",
        icon: "error",
        buttons: {
          confirm: { text: "Okay", className: "graden-bg " },
        },
      });
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  };

  function handleChange(e) {
    setImage(e.target.files[0]);
    // console.log("this is image :", e.target.files[0])

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result); // Set the image URL from the FileReader result
      };
      reader.readAsDataURL(file); // Read the file as a data URL (base64 encoded)
    }
  }
  return (
    <div className="w-full h-fit md:h-[calc(100vh-230px)] mx-auto px-4 md:px-10 py-4 bg-white rounded-lg shadow-lg">
      <h2 className="text-md md:text-xl font-semibold mb-6 text-center">Update Profile</h2>

      <div className="my-1">
        <Avatar
          size={100}
          src={
            imageUrl
              ? imageUrl
              : !currentUser?.image
              ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdO2DCDcfM7yDAtEo797CkSw_njibgz-lOgw&s"
              : UPLOADS_URL + currentUser?.image
          }
        />
        {editMode && (
          <RiEdit2Fill
            size={24}
            onClick={() => document.getElementById("file").click()}
          />
        )}
        <input
          type="File"
          id="file"
          name="image"
          style={{ display: "none" }}
          onChange={handleChange}
        />
      </div>

      {!editMode && (
        <div className="flex flex-col gap-4 md:gap-8 my-10 text-sm md:text-xl ">
          <div className="flex flex-col gap-0  md:flex-row md:gap-8">
            {" "}
            <h1>firstName</h1>{" "}
            <h1 className="font-bold">{currentUser?.firstName}</h1>
          </div>
          <div className="flex flex-col gap-0 md:flex-row md:gap-8">
            {" "}
            <h1>lastName</h1>{" "}
            <h1 className="font-bold">{currentUser?.lastName}</h1>
          </div>
          <div className="flex flex-col gap-0 md:flex-row md:gap-8">
            {" "}
            <h1>email</h1> <h1 className="font-bold">{currentUser?.email}</h1>
          </div>
          <div className="flex flex-col gap-0 md:flex-row md:gap-8">
            {" "}
            <h1>Phone</h1> <h1 className="font-bold">{currentUser?.phone}</h1>
          </div>
        </div>
      )}

      <Form
        name="updateForm"
        onFinish={onFinish}
        layout="vertical"
        className="space-y-4 w-auto max-w-md"
      >
        {editMode && (
          <>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
              initialValue={currentUser?.firstName} // Set initial value
            >
              <Input
                className="rounded-lg border-gray-300"
                disabled={!editMode}
              />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
              initialValue={currentUser?.lastName} // Set initial value
            >
              <Input
                className="rounded-lg border-gray-300"
                disabled={!editMode}
              />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please enter a valid email!" },
              ]}
              initialValue={currentUser?.email} // Set initial value
            >
              <Input className="rounded-lg border-gray-300" disabled={true} />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                { required: true, message: "Please input your phone number!" },
                // { pattern: /^[0-9]{10}$/, message: 'Please enter a valid phone number!' },
              ]}
              initialValue={currentUser?.phone} // Set initial value
            >
              <Input
                className="rounded-lg border-gray-300"
                disabled={!editMode}
              />
            </Form.Item>
          </>
        )}

        {!editMode ? (
          <Button
            type="primary"
            className="w-full py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
            onClick={() => setEditMode(true)}
          >
            Edit Profile
          </Button>
        ) : (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
            >
              {isLoading ? (
                <div class="w-6 h-6 border-4 border-t-4 border-white border-dashed rounded-full animate-spin"></div>
              ) : (
                "Update Profile"
              )}
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

export default EditProfile;
