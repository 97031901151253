import { Button, Col, Form, Image, Input, Row } from "antd";
import React from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { ImageUrl } from "../../config/helper";
import { FaArrowRight } from "react-icons/fa";

function ConvertCoins() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const token = useSelector((state) => state.user.userToken);

  const submit = (values) => {
    console.log("values", values)
    let data = {
      redCoins: +values.amount
    }
    Post('/coins/convert', data, token)
      .then(async (response) => {
        if (response?.status) {
          navigate('/')
          form.resetFields();
          swal("System Alert", response?.message, "success");
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  }

  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5>
            <IoIosArrowDropleftCircle onClick={() => navigate(-1)} />
            CONVERT COINS
          </h5>

          <div className="about-section product-details">
            <Row gutter={20} justify={'between'}>
              <Col xs={24} md={14} lg={12}>
                <span className="card-title">Convert Red Coins in Gold Coins</span>
                <br />
                <Form
                  name="basic"
                  onFinish={submit}
                  form={form}
                >
                  <Form.Item
                    name="amount"
                    rules={[{ required: true, message: 'Please input amount!' }]}
                  >
                    <Input placeholder="Amount" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Convert
                    </Button>
                  </Form.Item>
                </Form>

              </Col>
              <Col  xs={24} md={10} lg={12}>
                <Row className="flex flex-col sm:flex-row gap-4 justify-center items-center" >

                <Image
                  preview={false}
                  src={ImageUrl("redeem-coin-red.jpg")}
                  className="mb-15"
                  alt="card"
                  height={250}
                  width={250}
                />
                <FaArrowRight className="rotate-90 sm:rotate-0 "  size={40} />

                <Image
                  preview={false}
                  src={ImageUrl("redeem-coin-gold.jpg")}
                  className="mb-15"
                  alt="card"
                  height={250}
                  width={250}
                />
                  
                </Row>
              </Col>

            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ConvertCoins