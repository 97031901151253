import React from "react";
import Howl from "react-howler";
import { UPLOADS_URL } from "../../config/constants/api";

const BackgroundAudio = ({ src }) => {
    return (
        <Howl
            src={UPLOADS_URL + "Uploads/" + "baby_dragon.mp3"}
            playing={true}
            loop={true} // Loop the audio
            volume={0.8} // Adjust volume if needed
        />
    );
};

export default BackgroundAudio;
