import React, { useState } from "react";
import { Row, Col, Image } from "antd";
import { ImageUrl } from "../../config/helper";
import CardFrame from "../../components/card";
import AboutSection from "../../components/AboutSection";
import { RiArrowDownDoubleLine } from "react-icons/ri";
import ProductSlider from "../../components/productSlider";
import BottomSection from "../../components/bottomSection";

import { useNavigate, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { Get } from "../../config/api/get";
import { BACKGROUND_VIDEO, PRODUCTS, UPLOADS_URL } from "../../config/constants/api";

const getRandomThree = (array = []) => {
  return array
    .sort(() => 0.5 - Math.random()) // Shuffle array
    .slice(0, 3); // Take the first 3 items
};

const cardArray = [
  {
    key: 1,
    image: "TALO.png",
    title: "TALO",
    description: "Talo dragon is a powerful and versatile dragon that can be used in a variety of ways.",
  },
  {
    key: 2,
    image: "DRAKARIO.png",
    title: "DRAKARIO",
    description: "Drakario dragon is a powerful and versatile dragon that can be used in a variety of ways.",
  },
  {
    key: 3,
    image: "SERAPHIRLA.png",
    title: "SERAPHIRLA",
    description: "Seraphirla dragon is a powerful and versatile dragon that can be used in a variety of ways.",
  }
]

function Home() {
  const getProducts = async () => {
    const response = await Get(PRODUCTS.getAllProducts);
    if (response?.status) {
      return response?.data?.docs;
    } else {
      throw new Error("Error fetching user");
    }
  };
  const { data, error, isLoading } = useQuery(
    "getAllProducts", // Unique query key based on the user ID
    getProducts,
    {
      retry: 1, // Optional: Retry once if the query fails
      onError: (err) => console.log(err),
    }
  );

  return (
    <div>
      <Background />
      <div className="banner">
        <Row justify={"center"}>
          <Col xs={22} md={20}>
            <Row justify={"center"} gutter={20}>
              {cardArray?.map((item, index) => {
                return (
                  <Col xs={22} md={12} lg={6}>
                    <CardFrame item={item} index={index} products={Array.isArray(data) ? data : []} from="home" />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xs={22} md={20}>
            <h1 className="mt-3">
              Browse for Limited
              <br /> Edition Digital Cards
              {/* Browse Sword
              <br />& Shield Expansion */}
              <Link to="#about">
                Explore More <br />
                <RiArrowDownDoubleLine />
              </Link>
            </h1>
          </Col>
        </Row>
      </div>

      <Row justify={"center"}>
        <Col xs={22} md={20} className="text-center">
          {/* <Image  preview={false}  src={ImageUrl("giveawaybanner.png")} alt="" onClick={() => navigate("/give-away")} /> */}
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <AboutSection />
        </Col>
      </Row>

      {/* {Array.isArray(data) ? data : [] && (
        <ProductSlider
          title={"Buy Your Favorite Card"}
          heading={"live Auction"}
          products={data}
        />
      )} */}

      <BottomSection />
    </div>
  );
}

export default Home;

export const Background = () => {
  return (
    <div className="position-relative" style={{}} onClick={
      (e) => {
        e.preventDefault();
      }
    }>
      <video autoPlay loop muted playsInline className="bg-vid" onClick={(e) => {
        e.preventDefault();
      }}>
        <source src={BACKGROUND_VIDEO} type="video/mp4" onClick={
          (e) => {
            e.preventDefault();
          }
        } />{" "}
      </video>
    </div>
  )
} 