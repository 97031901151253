import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, CART, ORDER } from '../config/constants/api';

export const prepareHeaders = (headers, { getState }) => {
  try {
    // const token = getState()?.user?.userToken;
    const token = localStorage.getItem('userToken');
    
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
  
    return headers;
  } catch (error) {
    console.log(error)
  }
};

export function forceRefetch({ currentArg, previousArg }) {
  return true
  return currentArg !== previousArg
}

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
  tagTypes: ['Cart'],
  keepUnusedDataFor: 0,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getMyCart: builder.query({
      query: (name) => CART.getMyCart + name,
    }),
    getOrderById: builder.query({
      query: (id) => '/order/' + id,
    }),
    getClientId: builder.query({
      query: () => '/paypal/keys',
      transformResponse: (response) => response?.data?.clientId,
    }),
    getCoins: builder.query({
      query: () => '/coins',
      keepUnusedDataFor: 0,
      transformResponse: (response) => response?.data,
    }),
  }),
})

export const { useGetMyCartQuery, useGetOrderByIdQuery, useGetClientIdQuery, useGetCoinsQuery } = cartApi
