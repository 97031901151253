import React from "react";
import { Row, Col, Tabs } from "antd";

function PrivacyTerms() {
    const { TabPane } = Tabs;
  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={22}>
        <div className="tabs-container">
      
      <div className="tabs-content">
        <Tabs defaultActiveKey="1" tabPosition="left">
          {/* <TabPane tab="1. Privacy Policy" key="1">
            <h5>Privacy Policy</h5>
            <h3>Welcome to Proscomics.xyz!</h3>
            <p>This Privacy Policy explains how we collect, use, and disclose your information when you use our website (the "Website").</p>
            <h3>1. Information We Collect</h3>
            <p>
            We collect the following types of information from you:</p>
            <p><strong>Personal Information:</strong> This includes information that can be used to identify you, such as your name, email address, postal address, and phone number. We collect this information when you create an account, subscribe to our newsletter, or contact us.</p>
            <p><strong>Usage Data:</strong> This includes information about your activity on the Website, such as the pages you visit, the time you spend on the Website, and the links you click. We collect this information automatically using cookies and other tracking technologies.</p>
            <h3>2. How We Use Your Information</h3>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
              <li>To provide and operate the Website</li>
              <li>To create and maintain your account</li>
              <li>To create and maintain your account</li>
              <li>To send you newsletters and other promotional materials</li>
              <li>To respond to your inquiries and requests</li>
              <li>To analyze how you use the Website</li>
              <li>To improve the Website</li>
            </ul>
            <h3>3. Sharing Your Information</h3>
            <p>We may share your information with third-party service providers who help us operate the Website and provide our services. These service providers are contractually obligated to keep your information confidential and to use it only for the purposes for which we disclose it to them.</p>
            <p>We may also share your information with third-party advertisers who may use it to display targeted advertising to you. We will not share your personal information with any third-party advertisers without your consent.</p>

            <h3>4. Cookies and Other Tracking Technologies</h3>
            <p>We use cookies and other tracking technologies to collect information about your activity on the Website. Cookies are small files that are placed on your computer or device when you visit a website. They store information that can be retrieved by the website later.</p>
            <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies, you may not be able to access all or parts of the Website.</p>
            
            <h3>5. Data Retention</h3>
            <p>We will retain your information for as long as necessary to fulfill the purposes for which it was collected, as described in this Privacy Policy. We will also retain your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

            <h3>6. Children's Privacy</h3>
            <p>Our Website is not directed to children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you believe that your child has provided us with personal information, please contact us. We will take steps to remove the information from our records.</p>

            <h3>7. Your Choices</h3>
            <p>You have the following choices regarding your information:</p>
<ul>
  <li>You can access and update your personal information in your account settings.</li>
  <li>You can unsubscribe from our email list by clicking on the "unsubscribe" link in any email we send you.</li>
  <li>You can choose to refuse cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies</li>
</ul>
            

            <h3>8. Security</h3>
            <p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure. We cannot guarantee the security of your information.</p>
            

            <h3>9. Changes to this Privacy Policy</h3>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Website. You are advised to review this Privacy Policy periodically for any changes.</p>
           

            <h3>10. Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, please contact us</p>


          </TabPane> */}
          <TabPane tab="1. TERMS & CONDITIONS" key="1">
            <h5>terms & conditions</h5>

            {/* <h3>Welcome to Proscomics.xyz!</h3> */}
            <p>
              Thank you for choosing Real Money Dragon . By accessing or using our website and purchasing products or services from us, you agree to the following Terms and Conditions Please read these Terms carefully before making a purchase.
            </p>
            <p>
              These Terms apply to all purchases made through our website and govern the use of PayPal for payment processing.
            </p>
            <h3>1. Acceptance of Terms</h3>
            <p>
              By visiting our website and completing a purchase, you agree to be bound by these Terms and any other policies, agreements, or guidelines provided by us in connection with your purchase. If you do not agree with these Terms, you should not proceed with the purchase.
            </p>
            <h3>2. Payment Terms</h3>
                <p>
                  All payments on this website are processed through PayPal, a secure and reliable third-party payment processor. By making a purchase on our site, you agree to the following:
                </p>
                <ul>
                  <li><strong>Currency:</strong> All transactions are processed in  (e.g., USD, EUR, etc.).</li>
                  <li><strong>Payment Information:</strong> You must provide accurate and complete information when making a purchase. Any errors or inaccuracies in your payment details may result in a failed transaction or delayed processing.</li>
                  <li><strong>Authorization:</strong> By using PayPal, you authorize us to charge the total price of your order to your PayPal account.</li>
                </ul>
                <p><strong>Note:</strong> PayPal has its own Terms of Service, and by using their service, you agree to their terms in addition to these Terms and Conditions.</p>
                <h3>3. Order Acceptance and Confirmation</h3>
                <p>After placing an order, you will receive an email confirming the details of your purchase. This email is an acknowledgment of your order, not an acceptance. We reserve the right to refuse or cancel any order at our sole discretion, including after we have received payment, if there are concerns about fraud, availability, or any other issue related to the order.</p>

                <h3>4. No Refund or Return Policy</h3>
                <ul>
                  <li><strong>Final Sale:</strong> All sales are final. We do not offer refunds or returns once a purchase is completed, and all transactions are considered final when payment is made.</li>
                  <li><strong>Exceptions:</strong> No exceptions will be made, and we will not process any refund or return requests. Please review your order carefully before completing your purchase.</li>
                </ul>
                <p><strong>Note:</strong> You acknowledge that, by completing your purchase, you understand and agree to this no-refund policy.</p>

                <h3>5. Dispute Resolution and Chargebacks</h3>
                <p>If you believe there is an issue with your purchase, we encourage you to contact us directly so we can resolve the matter as quickly as possible.</p>
                <p>If you initiate a chargeback or dispute through PayPal, we will take the necessary steps to protect our interests, including providing PayPal with proof of transaction and any related documentation. Chargebacks or payment disputes may result in suspension of your account or service.</p>
                <p><strong>Note:</strong> We strongly encourage customers to contact us directly for resolution.</p>

                <h3>6. Product Availability and Accuracy</h3>
                <p>We strive to ensure that all product information and prices are accurate. However, errors may occur. If we discover an error in the price or description of an item, we will inform you before processing the order. We reserve the right to cancel any order due to pricing or availability errors.</p>
                <h3>7. Limitation of Liability</h3>
                <p>To the fullest extent permitted by law, [Your Business Name] shall not be liable for any indirect, incidental, special, or consequential damages arising out of your use of our website, including but not limited to lost profits, loss of data, or business interruption. Our liability to you for any claim arising from your purchase shall be limited to the amount paid for the product or service.</p>
                <h3>8. Privacy and Data Protection</h3>
                <p>We value your privacy. Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our website and completing a purchase, you consent to the practices described in our Privacy Policy.</p>

                <h3>9. Amendments to These Terms</h3>
                <p>We reserve the right to modify, update, or revise these Terms at any time. Any changes will be effective immediately upon posting on our website, and the revised Terms will be effective from the date of posting. We encourage you to review these Terms regularly for any updates.</p>

                <h3>10. Governing Law and Dispute Resolution</h3>
                <p>These Terms and Conditions shall be governed by and construed in accordance with the laws. Any disputes or claims arising out of or in connection with these Terms shall be resolved through [mediation/arbitration].</p>

                <h3>11. Contact Information</h3>
                <p>If you have any questions or concerns about these Terms and Conditions, please contact us</p>

                <h3>12. Acceptance of Terms</h3>
                <p>By completing a purchase, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>

            
          </TabPane>
        </Tabs>
      </div>
    </div>
        </Col>
      </Row>
    </div>
  );
}

export default PrivacyTerms;
