import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants/api';
import { forceRefetch, prepareHeaders } from './cart';

export const giveawayApi = createApi({
    reducerPath: 'giveawayApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/giveaway", prepareHeaders }),
    tagTypes: ['Giveaway'],
    keepUnusedDataFor: 0,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getAllGiveaways: builder.query({
            query: () => "/",
            transformResponse: (response) => response?.data?.giveaways,
        }),
        getGiveawayById: builder.query({
            query: (id) => "/" + id,
            transformResponse: (response) => response?.data?.giveaway,
        }),
        enterGiveaway: builder.mutation({
            query: (body) => ({
                url: "/enter",
                method: "POST",
                body,
            }),
        }),

        claimReward:builder.query({
            query: () => "/reward/claim",
            transformResponse: (response) => response,
        }),

      
    }),
})

export const { useGetAllGiveawaysQuery, useGetGiveawayByIdQuery, useEnterGiveawayMutation ,useLazyClaimRewardQuery} = giveawayApi
