import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants/api';
import { forceRefetch, prepareHeaders } from './cart';
import { createQueryString } from "./../config/helper";

export const postApi = createApi({
    reducerPath: 'postApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/post", prepareHeaders }),
    tagTypes: ['Post'],
    keepUnusedDataFor: 0,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getAllPost: builder.query({
            query: (param={}) => `/?${createQueryString(param)}`,
            transformResponse: (response) => response?.data,
        }),
        getPost: builder.query({
            query: (id) => "/" + id,
        }),
        likeUnlikePost: builder.mutation({
            query: (id) => ({
                url: `/${id}/like`,
                method: "POST",
            }),
        }),
        commentOnPost: builder.mutation({
            query: ({postId, body}) => ({
                url: `/${postId}/comment`,
                method: "POST",
                body,
            }),
        }),
        deleteComment: builder.mutation({
            query: ({postId, commentId}) => ({
                url: `${postId}/comment/${commentId}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const { useGetAllPostQuery, useGetPostQuery, useLikeUnlikePostMutation, useCommentOnPostMutation, useDeleteCommentMutation } = postApi