import React from 'react'
import { Row, Col, Image, Button } from "antd";
import { ImageUrl } from "../../config/helper";
import { useNavigate, Link } from "react-router-dom";
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from './AboutSection.module.css'

function ReduceText(text, limit) {
  return text.length > limit ? text.slice(0, limit) + "..." : text;
};

const aboutText =
  `Welcome to Real Money – Your #1 Trusted Site for Digital Card Collecting!
Dive into the world of digital cards with our comprehensive website portal! Connect with fellow enthusiasts in our vibrant social media community, where you can share news, tips, and fun experiences related to your digital card hobby.\n
Why Join Us?
- Stay Updated: Get the latest news in the digital card space.
- Engage with the Community: Join discussions, share your collections, and connect with other collectors.
- Exclusive Bonuses: Sign up today to receive special offers and bonuses!
Don’t miss out on the fun – sign up now and start your digital card adventure with us!`;

function AboutSection() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 767px)")
  return (
    <div className='about-section'>
      <Row gutter={20}>
        <Col xs={24} md={10} lg={8}>
          <div className="wow fadeInUp" data-wow-duration="2.5s" data-wow-delay="0.8s">
            <Image
              preview={false}
              src={ImageUrl("about-image1.png")}
              alt="card"

            />
          </div>

        </Col>
        <Col xs={24} md={14} lg={16} >
          <h3>About Us</h3>
          <p className={styles.aboutPara}>{isMobile ? ReduceText(aboutText, 400) : aboutText}</p>
          <Button className="mainbtn" onClick={() => navigate("/about-us")}>Read More</Button>
        </Col>
      </Row>

    </div>
  )
}

export default AboutSection