import React from "react";
import styles from "./claimReward.module.css";
// import rewardImage from "../../../public/images/rewardImage.png";
import rewardImage from "../.../../../assets/icons/rewardImage.png";

export default function ClaimReward({claimReward,isLoading}) {
  return (
    <>
      <div className={styles.website}>
        <section className={styles.websiteContent}>
          <div className={styles.cardReaward}>
            <div className="card-content text-center">
              <h3 className="mt-0 mb-2 text-3xl font-extrabold text-[#FFBA12]">Daily Reward</h3>
              <div className= {`${styles.glowingasset} ${styles.token} ${styles.isSm} flex justify-center items-center text-white `}>
                <img
                 className={styles.rewardImage}
                  // src="https://gloot.netlify.app/images/prototype/rewards.png"
                  src={rewardImage}
                  width="240"
                  height="auto"
                  alt=""
                />
                {/* <i>$10</i>
                <i>$20</i>
                <i>$30</i>
                <i>$40</i>
                <i>$50</i>
                <i>$60</i>
                <i>$70</i>
                <i>$80</i>
                <i>$90</i> */}
              </div>
              <p className="m-t-0 m-b-2 font-bold">
                Claim your daily reward Now
              </p>
              {/* <button className="button-glow is-glowing w-full mt-4 modal_open"> */}
              <button className={`${styles.buttonGlow} ${styles.isGlowing} w-full mt-4 ${styles.modal_open}`}>
                <span
                onClick={claimReward}
                className="text-white">{isLoading? "wait...":"Claim Reward"}</span>
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
// text-[#FFB711]