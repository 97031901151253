import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { UPLOADS_URL } from '../../config/constants/api';
import { useSelector } from 'react-redux';
import { useCommentOnPostMutation, useDeleteCommentMutation, useLikeUnlikePostMutation } from '../../api/post';
import { ImageUrl } from '../../config/helper';
import styles from './post.module.css';
import { Image } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

const Post = ({ postData }) => {
    const [likes, setLikes] = useState(postData?.likedBy?.length || 0);
    const [liked, setLiked] = useState(false);
    const [comments, setComments] = useState(postData?.comments || []);
    const [newComment, setNewComment] = useState('');
    const [showComments, setShowComments] = useState(false);
    const { _id: userId } = useSelector((state) => state.user.userData);
    const [likeUnlike, { isLoading: likeLoading }] = useLikeUnlikePostMutation();
    const [addComment, { isLoading: commentLoading }] = useCommentOnPostMutation();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            let ind = postData?.likedBy?.findIndex(x => x?._id === userId);
            if (ind > -1) {
                setLiked(true);
            }
        } catch (error) {
            console.log(error)
        }
    }, [postData])

    const handleLike = async () => {
        const { data } = await likeUnlike(postData._id);
        if (data?.status) {
            if (liked) {
                setLikes(likes - 1);
            } else {
                setLikes(likes + 1);
            }
            setLiked(!liked);
        }
    };

    const handleAddComment = async () => {
        if (commentLoading || !newComment) return;
        const body = {
            text: newComment,
        }
        const { data } = await addComment({ postId: postData._id, body });
        if (data?.status) {
            if (newComment.trim()) {
                setComments([...comments, { id: Date.now(), text: newComment }]);
                setNewComment('');
            }
        }
    };

    const media = [postData?.images?.map(val => {
        return { url: val, type: 'image' }
    }), postData?.videos?.map(val => {
        return { url: val, type: 'video' }
    })].flat().filter(Boolean);

    return (
        <div className="max-w-xxl mx-[-32px] md:mx-auto bg-black border border-[#dcb854]  text-gray-100 shadow-lg shadow-[#dcb854] rounded-lg overflow-hidden my-4">
            <div className="flex flex-row items-center px-1  md:px-4 py-3 border-[#dcb854] border shadow-sm shadow-[#dcb854]">
                <img
                    // src="https://via.placeholder.com/40"
                    src={ImageUrl("LOGO.svg")}
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full"
                />
                <div className="flex flex-col ml-3">
                    <h4 className="font-bold text-gray-100">
                        {postData?.title}
                    </h4>
                    <span className="text-sm text-gray-300">
                        {moment(postData?.createdAt).format('dddd, MMMM Do YYYY hh:mm A')}
                    </span>
                </div>
            </div>
            <div className="px-1  md:px-4  py-2">
                {/* <p className="">{postData?.description}</p> */}
                <div className="text-gray-300 p-3" dangerouslySetInnerHTML={{ __html: postData?.description }} />
                <div className="flex space-x-4 overflow-x-auto">
                    {/* {postData?.images?.length > 0 && (
                        <img
                            src={UPLOADS_URL + postData.images[0]}
                            alt="Post Content"
                            className="w-full h-64 object-cover mt-3 rounded-md"
                        />
                    )}
                    {postData?.videos?.length > 0 && (
                        <video
                            controls
                            className="w-full h-64 object-cover mt-3 rounded-md"
                            src={UPLOADS_URL + postData.videos[0]}
                        ></video>
                    )} */}
                    {pickRandomThree(media)?.map((val, index) => {
                        return val.type === 'image' ? (
                        //     <Image
                        //     key={index}
                        //     src={UPLOADS_URL + val?.url}
                        //     alt="Post Content"
                        //     className="w-full h-64 mt-3 rounded-md max-w-[350px] post-img"
                        //     style={{ objectFit: "unset" }}
                        //   />
                            // <a  href={`${UPLOADS_URL}${val?.url}`}>
                            <img
                                key={index}
                                src={UPLOADS_URL + val?.url}
                                alt="Post Content"
                                className="w-full mt-3 rounded-md max-w-[350px] "
                                style={{ objectFit: 'unset' }}    
                                // onClick={navigate("/google.com")}
                            />
                            // {/* </a> */}
                        ) : (
                            <video
                                key={index}
                                controls
                                className="w-full object-cover mt-3 rounded-md max-w-[300px] post-video"
                                src={UPLOADS_URL + val?.url}
                            ></video>
                        )
                    })}
                </div>
            </div>
            <div className="px-1  md:px-4  py-3 flex items-center justify-between">
                <button
                    onClick={handleLike}
                    disabled={likeLoading}
                    className={`px-3 py-1 rounded-md text-white ${liked ? 'bg-blue-500' : 'bg-gray-700'}`}
                >
                    {liked ? 'Unlike' : 'Like'} ({likes})
                </button>
                <button
                    onClick={() => setShowComments(!showComments)}
                    className="px-3 py-1 rounded-md bg-gray-700 text-white hover:bg-gray-600"
                >
                    {showComments ? 'Hide Comments' : 'Show Comments'}
                </button>
            </div>
            {showComments && (
                <div className="px-4 py-2 transition-all duration-300 ease-in-out">
                    <p className="font-bold text-gray-100 text-center text-[20px]">Comments</p>
                    {comments.length > 0 ? (
                        <ul className="mt-2 space-y-2">
                            {comments.map((val) => (
                                <li key={val.id}>
                                    <Comment  {...val} postId={postData?._id} />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-500">No comments yet.</p>
                    )}
                    <div className="py-3 flex items-center space-x-2">
                        <input
                            type="text"
                            placeholder="Add a comment..."
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            className="flex-1 px-4 py-2 bg-gray-800 text-gray-100 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            onClick={handleAddComment}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"

                        >
                            Comment
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Post;

const Comment = ({ _id, text, user, liked, likes, postId }) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteComment, { isLoading: deleteLoading }] = useDeleteCommentMutation();
    const handleLike = () => {
        // Handle like/unlike logic here
    };

    const handleDelete = async () => {
        // Logic to delete the comment (e.g., API call)
        if (deleteLoading) return;
        const { data, error } = await deleteComment({ postId, commentId: _id });
        if (data?.status) {
            setShowDeleteConfirm(false);
        }
    };

    return (
        <div className="flex flex-col space-y-4 bg-gray-800 p-4 rounded-lg shadow-md w-full max-w-xxl mx-auto">
            <div className="flex items-center space-x-3">
                <img
                    src="https://i.pravatar.cc/50?img=1"
                    alt="Avatar"
                    className="w-12 h-12 rounded-full"
                />
                <div className="flex flex-col items-center">
                    <span className="font-semibold text-white">
                        {user?.firstName + " " + user?.lastName}
                    </span>
                </div>
            </div>

            <p className="text-gray-700">{text}</p>

            <div className="flex items-center space-x-3">
                {/* <button
            onClick={handleLike}
            className={`flex items-center space-x-1 text-sm ${liked ? 'text-blue-500' : 'text-gray-500'}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-5 w-5 ${liked ? 'fill-blue-500' : 'fill-gray-500'}`}
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M3.172 4.172a4.5 4.5 0 116.364 6.364L10 12l.464-.464a4.5 4.5 0 116.364-6.364l.707.707a5.5 5.5 0 10-7.778 7.778l-.707.707-.707-.707A5.5 5.5 0 103.172 4.172z"
                clipRule="evenodd"
              />
            </svg>
            <span>{likes}</span>
          </button> */}
                {/* <button className="text-sm text-gray-500">Reply</button> */}
                {/* <button
                    onClick={() => setShowDeleteConfirm(true)}
                    className="text-sm text-red-500 hover:text-red-700"
                >
                    Delete
                </button> */}
            </div>

            {/* Confirmation Popup */}
            {showDeleteConfirm && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
                        <h3 className="text-lg font-semibold text-gray-800">Are you sure?</h3>
                        <div className="mt-4 flex space-x-4">
                            <button
                                onClick={handleDelete}
                                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700"
                            >
                                Yes, Delete
                            </button>
                            <button
                                onClick={() => setShowDeleteConfirm(false)}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

function pickRandomThree(array) {
    // Shuffle the array using Fisher-Yates algorithm
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    // Return the first 3 elements from the shuffled array
    return array.slice(0, 3);
}