import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ClientLayout from "../../components/layout";
import CheckOut from "../../views/checkout";
import ForgetPasswordCode from "../../views/fogetpasswordcode";
import ForgetPassword from "../../views/forgetpassword";
import Home from "../../views/home";
import Login from "../../views/login";
import MyCart from "../../views/myCart";
import PasswordRecovery from "../../views/passwordrecovery";
import PaymentDetails from "../../views/paymentDetails";
import PaymentSuccess from "../../views/paymentSuccess";
import ProductDetails from "../../views/productDetails";
import ProtectedRoute from "../../views/protectedRoute";
import Shop from "../../views/shop";
import Signup from "../../views/signup";
import RedeemCoins from "../../views/redeem";
import Coins from '../../views/coins';
import GiveaWay from '../../views/giveAways';
import WinnerList from '../../views/winnerList';
import TicketPackages from '../../views/ticket-packages';
import PodCast from '../../views/podcast';
import Community from '../../views/community';
import AboutPage from '../../views/about';
import PrivacyTerms from '../../views/policy';
import ContactUs from '../../views/contactUs';
import ConvertCoins from "../../views/convertCoins";
import RequestCoins from "../../views/requestCoins";
import Tickets from "../../views/tickets";
import GiveawayDetails from "../../views/giveAways/giveawayDetails";



import Profile from '../../views/profile';
import EditProfile from '../../views/profile/editProfile';
import ChangePassword from '../../views/profile/changePassword';
import Managesubscription from '../../views/profile/managesubscription';
import OrderHistory from '../../views/profile/orderhistory';
import OrderDetail from '../../views/profile/orderDetail';
import PayeeInfo from "../../views/profile/payee";
import AptPayment from "../../views/paymentDetails/aptPay";
import ChangeEmail from "../../views/profile/changeEmail";
import useIdleLogout from "../../hooks/useIdleLogout";
import Chat from "../../components/chat1";
import ConfirmOtp from "../../views/confirm-otp";
import { useDispatch, useSelector } from "react-redux";
import { isTokenExpired } from "../helper";
import { addUser, removeUser } from "../../redux/slice/authSlice";

// const AuthRoute = ({ component: Component, isAuthenticated, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       isAuthenticated ? (
//         <Component {...props} />
//       ) : (
//         <Redirect to="/login" />
//       )
//     }
//   />
// );

function MyRouter() {
  useIdleLogout();
  const token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("userData") && localStorage.getItem("userToken")) {
      dispatch(
        addUser({
          user: JSON.parse(localStorage.getItem("userData")),
          token: localStorage.getItem("userToken"),
        })
      );
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired(token)) {
        dispatch(removeUser());
        window.location.reload()
      }
    }, 60000); // Check every 1 minute

    return () => clearInterval(interval); // Cleanup interval
  }, [token]);

  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            path="/"
            index
            element={
              <ClientLayout header={true}>
                <Home />
              </ClientLayout>
            }
          />
          <Route
            path="/shop"
            index
            element={
              <ClientLayout header={true}>
                <Shop />
              </ClientLayout>
            }
          />
          <Route
            path="/convert-to-bank"
            index
            element={
              <ClientLayout header={true}>
                <RedeemCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/redeem-gold-coins"
            index
            element={
              <ClientLayout header={true}>
                <RedeemCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/convert-coins"
            index
            element={
              <ClientLayout header={true}>
                <ConvertCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/request-coins"
            index
            element={
              <ClientLayout header={true}>
                <RequestCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/product-details/:id"
            index
            element={
              <ClientLayout header={true}>
                <ProductDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/payment-success"
            index
            element={
              <ClientLayout header={true}>
                <PaymentSuccess />
              </ClientLayout>
            }
          />
          <Route
            path="/cart"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <MyCart />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <CheckOut />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-details/:orderId"
            index
            // lazy={true}
            element={
              <ClientLayout header={true}>
                <PaymentDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/payment/:orderId"
            index
            // lazy={true}
            element={
              <ClientLayout header={true}>
                <AptPayment />
              </ClientLayout>
            }
          />
          <Route
            path="/login"
            index
            element={
              <ClientLayout header={true}>
                <Login />
              </ClientLayout>
            }
          />
          <Route
            path="/Signup"
            index
            element={
              <ClientLayout header={true}>
                <Signup />
              </ClientLayout>
            }
          />

          <Route
            path="/confirm-otp"
            index
            element={
              <ClientLayout header={true}>
                <ConfirmOtp />
              </ClientLayout>
            }
          />

          <Route
            path="/forgetpassword"
            index
            element={
              <ClientLayout header={true}>
                <ForgetPassword />
              </ClientLayout>
            }
          />
          <Route
            path="/forgetpasswordcode"
            index
            element={
              <ClientLayout header={true}>
                <ForgetPasswordCode />
              </ClientLayout>
            }
          />
          <Route
            path="/passwordrecovery"
            index
            element={
              <ClientLayout header={true}>
                <PasswordRecovery />
              </ClientLayout>
            }
          />

          <Route
            path="/contact-us"
            index
            element={
              <ClientLayout header={true}>
                <ContactUs />
              </ClientLayout>
            }
          />
          <Route
            path="/chat"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <Chat />
                </ClientLayout>
              </ProtectedRoute>
            }
          />

          <Route path="/my-buxx-coins" index element={<ClientLayout header={true}><Coins /></ClientLayout>} />
          <Route path="/purchased-tickets" index element={<ClientLayout header={true}><Tickets /></ClientLayout>} />

          <Route
            path="/give-away"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <GiveaWay />
                </ClientLayout>
              </ProtectedRoute>
            } />


          <Route path="/give-away/:id" index element={<ClientLayout header={true}><GiveawayDetails /></ClientLayout>} />
          <Route path="/winner-list" index element={<ClientLayout header={true}><WinnerList /></ClientLayout>} />
          <Route path="/ticket-packages" index element={<ClientLayout header={true}><TicketPackages /></ClientLayout>} />
          <Route path="/podcast" index element={<ClientLayout header={true}><PodCast /></ClientLayout>} />
          <Route
            path="/community"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <Community />
                </ClientLayout>
              </ProtectedRoute>
            } />
          <Route path="/about-us" index element={<ClientLayout header={true}><AboutPage /></ClientLayout>} />
          <Route path="/privacy-terms" index element={<ClientLayout header={true}><PrivacyTerms /></ClientLayout>} />
          <Route path="/contact-us" index element={<ClientLayout header={true}><ContactUs /></ClientLayout>} />
          <Route path="/profile" element={<ClientLayout header={true}><Profile /></ClientLayout>} >
            <Route index element={<EditProfile />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="change-email" element={<ChangeEmail />} />
            <Route path="wishlist" element={<Managesubscription />} />
            <Route path="order-history" element={<OrderHistory />} />
            <Route path="order-detail/:id" element={<OrderDetail />} />
            <Route path="payee-info" element={<PayeeInfo />} />
          </Route>



        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MyRouter;
