import { MenuOutlined } from "@ant-design/icons";
import "animate.css";
import { Avatar, Button, Col, Drawer, Image, Menu, Popover, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import WOW from "wowjs";
import { useGetCoinsQuery, useGetMyCartQuery } from "../../api/cart";
import { ImageUrl } from "../../config/helper";
import useFetch from "../../hooks/useFetch";
import { removeUser } from "../../redux/slice/authSlice";
import { UPLOADS_URL } from "../../config/constants/api";
import { AiFillCaretDown } from "react-icons/ai";

const items = [
  {
    label: "Home",
    key: "/",
  },
  {
    label: "About Us",
    key: "/about-us",
  },
  {
    label: "Shop",
    key: "/shop",
  },
  {
    label: "Giveaways",
    key: "/give-away",
  },
  {
    label: "Community",
    key: "/community",
  },
  {
    label: "Chat",
    key: "/chat",
  },
  // {
  //   label: "Contact Us",
  //   key: "/contact-us",
  // },
  // {
  //   label: "My Buxx Coins",
  //   key: "/my-buxx-coins",
  // },
  // {
  //   label: "PODCAST",
  //   key: "/podcast",
  // },
  // {
  //   label: "COMMUNITY",
  //   key: "/community",
  // },
  // {
  //   label: "NIT Cards Auction",
  //   key: "/",
  // },
];

const itemsMobile = [
  ...items,
  {
    label: "Profile",
    key: "/profile",
    protected: true,
  },
  {
    label: "Submit To Your Financial",
    key: "/convert-to-bank",
    protected: true,
  },
  {
    label: "Submit Gold Coins",
    key: "/redeem-gold-coins",
    protected: true,
  },
  {
    label: "Convert Coins",
    key: "/convert-coins",
    protected: true,
  },
  {
    label: "Request Red Coins",
    key: "/request-coins",
    protected: true,
  },
  // {
  //   label: "Tickets",
  //   key: "/purchased-tickets",
  //   protected: true,
  // }
]

function Header({ header }) {
  const token = useSelector((state) => state.user.userToken);
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const [current, setCurrent] = useState("mail");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { data: cart, refetch: refetchCart } = useGetMyCartQuery("")
  // const { data: coins, refetch: refetchCoins } = useGetCoinsQuery("", {
  //   pollingInterval: 5000,

  // })
  const { data: coinsData, refetch: refetchCoins } = useFetch("/coins")
  const coins = coinsData?.data

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: "wow", // default
      animateClass: "animated", // default
      offset: 0, // default
      mobile: false, // default
      live: true, // default
    });
    wow.init();
  }, []);

  const content = (
    <div>
      <Button className="profile_btn" onClick={() => navigate("/profile")}>
        Profile
      </Button>
      <Button className="profile_btn" onClick={() => navigate("/convert-to-bank")}>
        Submit To Your Financial
      </Button>
      <Button className="profile_btn" onClick={() => navigate("/redeem-gold-coins")}>
        Submit Gold Coins
      </Button>
      <Button className="profile_btn" onClick={() => navigate("/request-coins")}>
        Request Red Coins
      </Button>
      <Button className="profile_btn" onClick={() => navigate("/convert-coins")}>
        Convert Coins
      </Button>
      {/* <Button className="profile_btn" onClick={() => navigate("/purchased-tickets")}>
        Tickets
      </Button> */}
      <Button className="profile_btn" onClick={() => {
        dispatch(removeUser());
        navigate("/login");
      }}>Logout</Button>
    </div>
  );
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onClick = (e) => {
    console.log("click ", e);
    navigate(e.key);
    setCurrent(e.key);
    setOpen(false);
  };

  const clickCart = async () => {
    onClose(false)
    let { data } = await refetchCart();
    if (data?.cart?.products?.length > 0) {
      navigate("/cart");
    } else {
      swal("Oops!", data?.message || '', "error");
    }
  }

  useEffect(() => {
    refetchCoins()
  }, [window.location.pathname])

  // const menuItems = useMemo(() => {
  //   let arr = []
  //   if (isMobile) {
  //     arr = [...items, ...itemsMobile]
  //   } else {
  //     arr = [...items]
  //   }
  //   if (!token) {
  //     arr = arr.filter(val => !val.protected)
  //   }
  //   return arr
  // }, [isMobile, token])

  return (
    <Row justify={"center"} className="navigation">
      <Col xs={22} lg={20}>
        <Row style={{ alignItems: "center" }}>
          <Col xs={12} sm={12} lg={4} xl={4}>
            <Image
              className="logo"
              preview={false}
              src={ImageUrl("logo.png")}
              alt="logo"
            />
          </Col>
          <Col xs={0} sm={0} md={0} lg={13} xl={14} justify="center">
            <Menu
              className="mainMenu"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={token ? items : items.filter(val => !val.protected)}
            />
          </Col>
          <Col xs={12} sm={12} lg={7} xl={6} justify={"end"}>
            <Row style={{ width: "100%", padding: "0px 50px" }}></Row>

            <Row align={"middle"}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={0}
                style={{ textAlign: "right" }}
              >
                <MenuOutlined
                  style={{ fontSize: "25px" }}
                  onClick={() => showDrawer()}
                />
              </Col>
              <Col lg={24} md={0} sm={0} xs={0}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {token ? <>
                    {/* {false && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 40 }}>
                      <Image className="logo" preview={false} src={ImageUrl("dollar.png")} alt="banner" width={60} height={60} />
                      <p style={{ color: 'white' }}>$ {userData?.goldCoins}</p>
                    </div>} */}
                    {/* show red coins and from user */}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 40 }}>
                      <Image
                        preview={false}
                        src={ImageUrl("red-coin.png")}
                        height={30}
                        width={30}
                        alt="red"
                      />
                      <p style={{ color: 'white' }}> &nbsp;&nbsp;</p>
                      <p style={{ color: 'white' }}>{coins?.redCoins} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                      <Image
                        preview={false}
                        src={ImageUrl("gold-coin.png")}
                        height={30}
                        width={30}
                        alt="red"
                      />
                      <p style={{ color: 'white' }}> &nbsp;&nbsp;&nbsp;</p>
                      <p style={{ color: 'white' }}>{coins?.goldCoins}</p>
                    </div>

                    <FaShoppingCart onClick={clickCart} style={{ marginRight: 30 }} />

                    <Avatar
                      size={40}
                      src={!user?.image ? ImageUrl("avatar.png") : UPLOADS_URL + user?.image}
                    />


                    <Popover content={content} style={{ width: '100%' }}>
                      <p style={{ color: 'white', marginLeft: 10 }}>{user?.fullName} <AiFillCaretDown fontSize={12} /></p>
                    </Popover></>
                    :
                    <>
                      <Button
                        className="mainbtn"
                        onClick={() => navigate("/Signup")}
                      >
                        Register
                      </Button>

                      <Button
                        className="mainbtn"
                        onClick={() => navigate("/login")}
                      >
                        <FaUser /> LOGIN{" "}
                      </Button>
                    </>}

                  {/* <Popover content={content} style={{width: '100%'}}>
                    <Button className="profile">

                      <Image className="logo" preview={false} src={ImageUrl("logo.png")} alt="banner" />
                    </Button>
                  </Popover> */}
                  {/* {!token && <Button
                    className="mainbtn"
                    onClick={() => navigate("/Signup")}
                  >
                    Register
                  </Button>}

                  {!token && <Button
                    className="mainbtn"
                    onClick={() => navigate("/login")}
                  >
                    <FaUser /> LOGIN{" "}
                  </Button>} */}
                </div>
              </Col>
            </Row>
            {/* */}

            <Drawer
              placement={"left"}
              closable={true}
              onClose={onClose}
              open={open}
              key={"left"}
              className="menu-drawer"
            >
              <Menu
                className="mainMenu "
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={token ? itemsMobile : itemsMobile.filter(val => !val.protected)}
              />
              <div
                className="mobileprofile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "5px",
                }}
              >
                <Popover content={content}>
                  {/* <Button className="profile">
                <Image className="logo" preview={false}  src={ImageUrl("logo.png")} alt="banner" />
              </Button> */}
                </Popover>


                {!token ? <Link to="/">

                  <Button className="mainbtn" onClick={(e) => {
                    try {
                      e.preventDefault()
                      navigate("/Signup")
                      setOpen(false)
                    } catch (error) {
                      console.log(error)
                    }
                  }}>Register</Button>
                </Link> :
                  <>
                    <div className="w-full">
                      <div className="flex flex-row justify-between w-full items-center px-2">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 40, height: 44 }}>
                          <Image
                            preview={false}
                            src={ImageUrl("red-coin.png")}
                            height={30}
                            width={30}
                            alt="red"
                          />
                          <p style={{ color: 'black' }}> &nbsp;&nbsp;</p>
                          <p style={{ color: 'black' }}>{coins?.redCoins} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                          <Image
                            preview={false}
                            src={ImageUrl("gold-coin.png")}
                            height={30}
                            width={30}
                            alt="red"
                          />
                          <p style={{ color: 'black' }}> &nbsp;&nbsp;&nbsp;</p>
                          <p style={{ color: 'black' }}>{coins?.goldCoins}</p>
                        </div>
                        <FaShoppingCart onClick={clickCart} color="black" style={{ marginLeft: 30, color: 'black' }} />
                      </div>
                      <div className="mt-3 flex flex-row justify-end items-center">
                        <Button className="mainbtn" onClick={(e) => {
                          try {
                            e.preventDefault()
                            dispatch(removeUser());
                            navigate("/login")
                            setOpen(false)
                          } catch (error) {
                            console.log(error)
                          }
                        }}>Logout</Button>
                      </div>
                    </div>
                  </>

                }
                {!token &&
                  <Button className="mainbtn" onClick={(e) => {
                    try {
                      e.preventDefault()
                      navigate("/login")
                      setOpen(false)
                    } catch (error) {
                      console.log(error)
                    }
                  }}>
                    <FaUser /> LOGIN{" "}
                  </Button>
                }
              </div>
            </Drawer>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Header;
