import React, { useState } from "react";
import { Row, Col, Image, Pagination } from "antd";
import { ImageUrl } from "../../config/helper";
// import Post from '../../components/post';
import PostHead from "../../components/postHead";
import Post from "../../components/post/Post";
import { useGetAllPostQuery } from "../../api/post";

function Community() {
  
  const [page, setPage] = useState(1);
  const limit = 10;

  const { data: postData, isLoading, isError, refetch } = useGetAllPostQuery({
    page,
    limit,
  });
  
  const message = `Showing records ${postData?.docs?.length || 0} of ${postData?.totalDocs || 0}`

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handlePageChange = (pageNumber) => {
    console.log("Page Number", pageNumber);
    setPage(pageNumber);
    window.scrollTo(0, 150);
    refetch(pageNumber, limit);
  };

  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5 className="mt-50">Community</h5>
        </Col>
        <Col xs={22} md={20}>
          <div className="golden-border">
            {/* <PostHead /> */}
            {postData?.docs?.map((val) => (
              <Post key={val?._id} postData={val} />
            ))}
          </div>
        </Col>

        <Col xs={22} md={20}>
          <Row style={{ padding: "10px 20px" }}>
            <Col xs={24} md={12}>
              <p style={{ color: "white" }}>{message}</p>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                className="styledPagination"
                onChange={handlePageChange}
                current={parseInt(page)}
                pageSize={limit}
                total={postData?.totalDocs}
                itemRender={itemRender}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Community;
