import React from 'react'
import ChatComponent from './chatComponent'

const Chat = () => {
  return (
    <div className="w-full h-full py-24  lg:mb-[-78px] lg:h-screen flex justify-center ">
      <ChatComponent />
    </div>
  )
}

export default Chat